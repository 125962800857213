@import "../../../data/styles.css";

.education-body {
	margin-bottom: 5px;
	margin-top: -20px;
}

.education {
	display: flex;
	padding-bottom: 20px;
	padding-top: 5px;
	width: 100%;
	flex-wrap: wrap;
}

.education-image {
	height: 30px;
	width: 30px;
	border-radius: 50%;
	outline: 6px solid white;
	box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
}

.education-title {
	font-size: 15px;
	font-weight: 700;
	padding-left: 20px;
	margin-top: -3px;
	color: var(--secondary-color);
}

.education-subtitle {
	position: absolute;
	font-size: 12px;
	color: var(--secondary-color);
	padding-top: 22px;
	padding-left: 50px;
}

.education-duration {
	position: absolute;
	width: 950px;
	font-size: 12px;
	padding-top: 20px;
	text-align: right;
	color: var(--tertiary-color);
}

.education-description {
    margin-top: 10px; /* 從上一個元素的底部添加一些間距 */
    font-size: 14px; /* 設置文字大小 */
    line-height: 1.5; /* 設置行高，使文字更易於閱讀 */
    color: #333; /* 設置文字顏色 */
    padding-top: 20px; /* 添加頂部內間距 */
    margin-left: -40px; /* 移除左側邊框 */
}

.education-description ul {
    list-style-type: disc;
    padding-left: 0px;
	margin-bottom: 0px;
}

.education-description li {
    margin-bottom: 0px;
	padding-left: 0px;
}