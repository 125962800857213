@import "../../../data/styles.css";

.works-body {
	margin-bottom: 5px;
	margin-top: -20px;
}

.work {
	display: flex;
	padding-bottom: 15px;
	width: 100%;
	flex-wrap: wrap;
}

.work-image {
	height: 30px;
	width: 30px;
	border-radius: 50%;
	outline: 6px solid white;
	box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
}

.work-title {
	font-size: 15px;
	font-weight: 700;
	padding-left: 20px;
	margin-top: -3px;
	color: var(--secondary-color);
	flex-shrink: 0; /* 防止文本被壓縮 */
}

.work-subtitle {
	position: absolute;
	font-size: 12px;
	color: var(--secondary-color);
	padding-top: 22px;
	padding-left: 50px;
}

.work-duration {
	position: absolute;
	width: 950px;
	font-size: 12px;
	padding-top: 20px;
	text-align: right;
	color: var(--tertiary-color);
}

.work-description {
    margin-top: 15px; /* 從上一個元素的底部添加一些間距 */
    font-size: 14px; /* 設置文字大小 */
    line-height: 1.5; /* 設置行高，使文字更易於閱讀 */
    color: #333; /* 設置文字顏色 */
	text-align: left;
	width: 100%;
    padding-top: 0px; /* 添加頂部內間距 */
}

.work-description ul {
    list-style-type: disc; /* 保持原有的列表類型為圓點 */
    padding-left: 60px; /* 為列表添加左側內間距，以保持一致性和美觀性 */
    margin-bottom: 0px; /* 移除列表的底部間距 */
	margin-top: 0px;
}

.work-description li {
    margin-bottom: 5px; /* 為列表項添加底部間距，以提高可讀性 */
    padding-left: 0px; /* 移除列表項的左側內間距 */
}
